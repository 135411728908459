import React from 'react';
import { Container, Header, Icon, Button } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

const PaymentConfirmation = () => {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');

  return (
    <div style={{
      background: `
        linear-gradient(135deg, 
          rgba(255, 255, 255, 0.97) 0%,
          rgba(247, 249, 252, 0.97) 100%
        )
      `,
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2em',
      position: 'relative',
      overflow: 'hidden',
    }}>
      {/* 動態背景效果 */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `
          radial-gradient(circle at 20% 30%, rgba(74, 144, 226, 0.08) 0%, transparent 50%),
          radial-gradient(circle at 80% 70%, rgba(52, 152, 219, 0.08) 0%, transparent 50%),
          radial-gradient(circle at 50% 50%, rgba(41, 128, 185, 0.05) 0%, transparent 70%)
        `,
        animation: 'pulse 8s ease-in-out infinite alternate',
      }} />

      {/* 動態幾何圖形 */}
      <div style={{
        position: 'absolute',
        top: '10%',
        right: '15%',
        width: '200px',
        height: '200px',
        background: 'linear-gradient(135deg, rgba(74, 144, 226, 0.2) 0%, rgba(52, 152, 219, 0.2) 100%)',
        borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
        animation: 'morphing 15s ease-in-out infinite',
        opacity: 0.7,
      }} />

      <div style={{
        position: 'absolute',
        bottom: '15%',
        left: '10%',
        width: '150px',
        height: '150px',
        background: 'linear-gradient(135deg, rgba(52, 152, 219, 0.2) 0%, rgba(41, 128, 185, 0.2) 100%)',
        borderRadius: '60% 40% 30% 70% / 60% 30% 70% 40%',
        animation: 'morphing 12s ease-in-out infinite reverse',
        opacity: 0.5,
      }} />

      {/* 動畫樣式 */}
      <style>{`
        @keyframes morphing {
          0% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
          25% { border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%; }
          50% { border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%; }
          75% { border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%; }
          100% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
        }

        @keyframes pulse {
          0% { opacity: 0.3; }
          50% { opacity: 0.5; }
          100% { opacity: 0.3; }
        }
      `}</style>

      <Container text style={{
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(20px)',
        padding: '3em',
        borderRadius: '30px',
        boxShadow: '0 20px 40px rgba(0, 0, 0, 0.1), 0 0 100px rgba(74, 144, 226, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        textAlign: 'center',
        position: 'relative',
        zIndex: 2,
      }}>
        <Icon 
          name='check circle' 
          size='huge' 
          style={{
            color: '#4CAF50',
            marginBottom: '0.5em',
            animation: 'float 6s ease-in-out infinite',
          }}
        />
        <Header 
          as='h1' 
          style={{
            fontSize: '2.5em',
            fontWeight: '800',
            marginBottom: '0.5em',
            background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          付款後導向頁面

        </Header>
        <div style={{
          fontSize: '1.2em',
          color: '#34495e',
          marginBottom: '2em',
          lineHeight: '1.6',
          background: 'rgba(76, 175, 80, 0.1)',
          padding: '1.5em',
          borderRadius: '15px',
        }}>
          <p style={{ marginBottom: '1em' }}>
            已完成付款流程，我們已將交易結果發送到您的電子郵件地址。
          </p>
          <p style={{ marginBottom: '1em' }}>
            若為交易成功，我們也已將加值碼發送到您的電子郵件地址，
            <strong style={{ color: '#2980b9' }}>{email}</strong>
          </p>
          <p>請至您的信箱（包括垃圾郵件匣）確認交易結果並獲得加值碼！</p>
        </div>
        <p style={{
          fontSize: '1.1em',
          color: '#7f8c8d',
          marginBottom: '2em',
        }}>
          如果您在 15 分鐘內沒有收到加值碼，請聯繫我們的客服人員。
        </p>
        <div style={{ display: 'flex', gap: '1em', justifyContent: 'center' }}>
          <Button
            as='a'
            href="https://api.stima.tech/topup"
            style={{
              background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
              color: 'white',
              padding: '1em 2em',
              fontSize: '1.1em',
              fontWeight: '600',
              borderRadius: '8px',
              border: 'none',
              boxShadow: '0 4px 15px rgba(52, 152, 219, 0.3)',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              cursor: 'pointer',
              letterSpacing: '0.5px',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-2px) scale(1.02)';
              e.currentTarget.style.boxShadow = '0 8px 25px rgba(52, 152, 219, 0.4)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'translateY(0) scale(1)';
              e.currentTarget.style.boxShadow = '0 4px 15px rgba(52, 152, 219, 0.3)';
            }}
          >
            <Icon name='plus' />
            前往加值
          </Button>
          <Button
            as='a'
            href="mailto:support@stima.tech"
            style={{
              background: 'transparent',
              color: '#3498db',
              padding: '1em 2em',
              fontSize: '1.1em',
              fontWeight: '600',
              borderRadius: '8px',
              border: '2px solid #3498db',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              cursor: 'pointer',
              letterSpacing: '0.5px',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.background = 'rgba(52, 152, 219, 0.1)';
              e.currentTarget.style.transform = 'translateY(-2px)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.background = 'transparent';
              e.currentTarget.style.transform = 'translateY(0)';
            }}
          >
            <Icon name='mail' />
            聯繫客服
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default PaymentConfirmation;