import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/User';

import { Button, Container, Dropdown, Icon, Menu, Segment } from 'semantic-ui-react';
import { API, getLogo, getSystemName, isAdmin, isMobile } from '../helpers';
import '../index.css';

// Header Buttons
let headerButtons = [
  // {
  //   name: '首頁',
  //   to: '/',
  //   icon: 'home'
  // },
  {
    name: '使用指南',
    to: 'https://docs.stima.tech',
    icon: 'book',
    external: true 
  },
  {
    name: '管道',
    to: '/channel',
    icon: 'sitemap',
    admin: true
  },
  {
    name: '購買',
    to: 'https://payment.stima.tech',
    icon: 'credit card',
    external: true
  },
  {
    name: 'API Key',
    to: '/token',
    icon: 'key'
  },
  {
    name: '模型列表',
    to: 'https://api.stima.tech/#pricing',
    icon: 'cubes',
    external: true,
    currentTab: true
  },
  {
    name: '兌換管理',
    to: '/redemption',
    icon: 'dollar sign',
    admin: true
  },
  {
    name: '加值',
    to: '/topup',
    icon: 'plus'
  },
  {
    name: '使用者',
    to: '/user',
    icon: 'user',
    admin: true
  },
  {
    name: '日誌',
    to: '/log',
    icon: 'history',
  },
  {
    name: '設定',
    to: '/setting',
    icon: 'setting'
  },
];

// if (localStorage.getItem('chat_link')) {
//   headerButtons.splice(1, 0, {
//     name: '聊天',
//     to: '/chat',
//     icon: 'comments'
//   });
// }

const Header = () => {
  const [userState, userDispatch] = useContext(UserContext);
  let navigate = useNavigate();
  let location = useLocation();

  const [showSidebar, setShowSidebar] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const systemName = getSystemName();
  const logo = getLogo();

  const headerStyle = {
    background: '#ffffff',
    boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
    borderBottom: '1px solid rgba(52, 152, 219, 0.1)',
    padding: '8px 0',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  };

  const logoStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.3em',
    fontWeight: '600',
    color: '#2c3e50',
    letterSpacing: '0.5px',
    padding: '0 1em',
    height: '100%',
    transition: 'all 0.2s ease',
    '&:hover': {
      color: '#3498db',
    }
  };

  const logoImageStyle = {
    width: 'auto',
    height: '35px',
    marginRight: '0.5em',
    objectFit: 'contain'
  };

  const getMenuItemStyle = (isActive, isHovered) => ({
    color: isActive ? '#3498db' : isHovered ? '#3498db' : '#2c3e50',
    padding: '0.8em 1.2em',
    fontSize: '0.95em',
    fontWeight: isActive ? '600' : '500',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    cursor: 'pointer',
    position: 'relative',
    letterSpacing: '0.3px',
    marginLeft: '0.5em',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    border: 'none',
  });

  const loginButtonStyle = (isActive, isHovered) => ({
    background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
    color: 'white',
    padding: '0.8em 1.8em',
    fontSize: '0.95em',
    fontWeight: '600',
    borderRadius: '8px',
    border: 'none',
    boxShadow: isHovered 
      ? '0 8px 20px rgba(52, 152, 219, 0.25)' 
      : '0 4px 12px rgba(52, 152, 219, 0.15)',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    letterSpacing: '0.5px',
    marginLeft: '1em',
    transform: isHovered ? 'translateY(-2px) scale(1.02)' : 'translateY(0) scale(1)',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      background: 'linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)',
      opacity: isHovered ? 1 : 0,
      transition: 'opacity 0.3s ease',
    }
  });

  async function logout() {
    setShowSidebar(false);
    await API.get('/api/user/logout');
    // showSuccess('登出成功!');
    userDispatch({ type: 'logout' });
    localStorage.removeItem('user');
    navigate('/login');
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const renderButtons = (isMobile) => {
    return headerButtons.map((button, index) => {
      if (button.admin && !isAdmin()) return null;
      const isActive = location.pathname === button.to;
      const isHovered = hoveredItem === button.name;
      
      if (isMobile) {
        return (
          <Menu.Item
            key={button.name || index}
            onClick={() => {
              if (button.external) {
                if (button.currentTab) {
                  window.location.href = button.to;
                } else {
                  window.open(button.to, '_blank', 'noopener,noreferrer');
                }
              } else {
                navigate(button.to);
              }
              setShowSidebar(false);
            }}
            style={getMenuItemStyle(isActive, isHovered)}
          >
            <div style={{
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              transform: isHovered ? 'translateY(-1px)' : 'translateY(0)',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            }}>
              <Icon 
                name={button.icon} 
                style={{
                  marginRight: '6px',
                  fontSize: '1.1em',
                  color: isActive ? '#3498db' : isHovered ? '#3498db' : '#7f8c8d',
                  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                }} 
              />
              <span>
                {button.name}
              </span>
            </div>
            <div style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              background: isHovered ? 'rgba(52, 152, 219, 0.05)' : 'transparent',
              borderRadius: '4px',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              zIndex: 0,
            }} />
            <div style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              height: '2px',
              background: 'linear-gradient(90deg, #3498db, #2980b9)',
              transform: `scaleX(${isHovered || isActive ? 1 : 0})`,
              transformOrigin: isHovered ? 'left' : 'right',
              transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              zIndex: 1,
            }} />
          </Menu.Item>
        );
      }
      return (
        <Menu.Item 
          key={button.name} 
          as={button.external ? 'a' : Link}
          to={button.external ? undefined : button.to}
          href={button.external ? button.to : undefined}
          target={button.external && !button.currentTab ? '_blank' : undefined}
          rel={button.external && !button.currentTab ? 'noopener noreferrer' : undefined}
          style={getMenuItemStyle(isActive, isHovered)}
          onMouseEnter={() => setHoveredItem(button.name)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div style={{
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            transform: isHovered ? 'translateY(-1px)' : 'translateY(0)',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          }}>
            <Icon 
              name={button.icon} 
              style={{
                marginRight: '6px',
                fontSize: '1.1em',
                color: isActive ? '#3498db' : isHovered ? '#3498db' : '#7f8c8d',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              }} 
            />
            <span>
              {button.name}
            </span>
          </div>
          <div style={{
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            background: isHovered ? 'rgba(52, 152, 219, 0.05)' : 'transparent',
            borderRadius: '4px',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            zIndex: 0,
          }} />
          <div style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            height: '2px',
            background: 'linear-gradient(90deg, #3498db, #2980b9)',
            transform: `scaleX(${isHovered || isActive ? 1 : 0})`,
            transformOrigin: isHovered ? 'left' : 'right',
            transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            zIndex: 1,
          }} />
        </Menu.Item>
      );
    }).filter(Boolean);
  };

  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSidebar]);

  if (isMobile()) {
    return (
      <>
        <Menu
          borderless
          size='large'
          style={{
            ...headerStyle,
            marginBottom: showSidebar ? '0' : undefined,
          }}
        >
          <Container>
            <Menu.Item as={Link} to='/' style={logoStyle}>
              <img
                src={logo}
                alt='logo'
                style={logoImageStyle}
              />
              <div>{systemName}</div>
            </Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item onClick={toggleSidebar}>
                <Icon name={showSidebar ? 'close' : 'sidebar'} size='large' style={{color: '#555555'}} />
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
        {showSidebar && (
          <Segment style={{ 
            marginTop: '60px',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            overflow: 'auto',
            background: '#f8f9fa',
            padding: 0,
            border: 'none',
            boxShadow: 'none'
          }}>
            <Menu 
              secondary 
              vertical 
              style={{ 
                width: '100%', 
                margin: 0,
                borderRadius: 0,
                height: 'auto',
                minHeight: '100%',
                background: '#ffffff',
                boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
              }}
            >
              {renderButtons(true)}
              <Menu.Item style={{ padding: '15px', background: 'transparent' }}>
                {userState.user ? (
                  <Button 
                    fluid 
                    onClick={logout} 
                    style={{
                      background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
                      color: 'white',
                      padding: '12px 24px',
                      fontSize: '0.95em',
                      fontWeight: '600',
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: '0 4px 12px rgba(52, 152, 219, 0.15)',
                      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                      letterSpacing: '0.5px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    登出
                  </Button>
                ) : (
                  <div style={{ 
                    display: 'flex', 
                    gap: '12px',
                    width: '100%'
                  }}>
                    <Button
                      fluid
                      onClick={() => {
                        setShowSidebar(false);
                        navigate('/login');
                      }}
                      style={{
                        flex: 1,
                        background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
                        color: 'white',
                        padding: '12px 24px',
                        fontSize: '0.95em',
                        fontWeight: '600',
                        borderRadius: '8px',
                        border: 'none',
                        boxShadow: '0 4px 12px rgba(52, 152, 219, 0.15)',
                        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                        letterSpacing: '0.5px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        overflow: 'hidden'
                      }}
                    >
                      登入
                    </Button>
                    <Button
                      fluid
                      onClick={() => {
                        setShowSidebar(false);
                        navigate('/register');
                      }}
                      style={{
                        flex: 1,
                        background: 'white',
                        color: '#3498db',
                        padding: '12px 24px',
                        fontSize: '0.95em',
                        fontWeight: '600',
                        borderRadius: '8px',
                        border: '2px solid #3498db',
                        boxShadow: '0 4px 12px rgba(52, 152, 219, 0.1)',
                        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                        letterSpacing: '0.5px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        overflow: 'hidden'
                      }}
                    >
                      註冊
                    </Button>
                  </div>
                )}
              </Menu.Item>
            </Menu>
          </Segment>
        )}
      </>
    );
  }

  return (
    <Menu borderless style={headerStyle}>
      <Container style={{height: '100%'}}>
        <Menu.Item as={Link} to='/' className={'hide-on-mobile'} style={{...logoStyle, height: '100%'}}>
          <img src={logo} alt='logo' style={logoImageStyle} />
          <div>{systemName}</div>
        </Menu.Item>
        {renderButtons(false)}
        <Menu.Menu position='right' style={{height: '100%'}}>
          {userState.user ? (
            <Dropdown
              text={userState.user.username}
              pointing
              className='link item'
              style={getMenuItemStyle(false, hoveredItem === 'user-dropdown')}
              onMouseEnter={() => setHoveredItem('user-dropdown')}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <Dropdown.Menu>
                <Dropdown.Item 
                  onClick={logout}
                  style={{
                    color: '#2c3e50',
                    fontSize: '0.95em',
                    padding: '0.8em 1.2em',
                  }}
                >
                  登出
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item
              name='登入'
              as={Link}
              to='/login'
              style={loginButtonStyle(location.pathname === '/login', hoveredItem === 'login')}
              onMouseEnter={() => setHoveredItem('login')}
              onMouseLeave={() => setHoveredItem(null)}
            />
          )}
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default Header;
