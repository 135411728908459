import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Image, Divider, Modal } from 'semantic-ui-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../context/User';
import { API, getLogo, showError, showSuccess, showWarning } from '../helpers';
import { onGitHubOAuthClicked, onLarkOAuthClicked } from './utils';
import larkIcon from '../images/lark.svg';
import styled from 'styled-components';
import CryptoJS from 'crypto-js';

// 添加高級加密存儲功能
const secureStorage = {
  // 生成安全的加密金鑰
  getSecureKey: () => {
    let key = localStorage.getItem('secure_key');
    if (!key) {
      // 生成一個隨機的金鑰並存儲
      key = CryptoJS.lib.WordArray.random(256/8).toString();
      localStorage.setItem('secure_key', key);
    }
    return key;
  },

  // 使用 AES 加密
  encrypt: (text, customKey = null) => {
    const key = customKey || secureStorage.getSecureKey();
    try {
      return CryptoJS.AES.encrypt(text, key).toString();
    } catch (error) {
      console.error('Encryption error:', error);
      return '';
    }
  },

  // 使用 AES 解密
  decrypt: (encrypted, customKey = null) => {
    const key = customKey || secureStorage.getSecureKey();
    try {
      const bytes = CryptoJS.AES.decrypt(encrypted, key);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Decryption error:', error);
      return '';
    }
  },

  // 安全存儲資料
  setItem: (key, value) => {
    try {
      // 添加時間戳和隨機雜湊以增加安全性
      const timestamp = new Date().getTime();
      const salt = CryptoJS.lib.WordArray.random(128/8).toString();
      const data = {
        value,
        timestamp,
        salt,
        version: '1.0'  // 用於未來可能的加密方案升級
      };
      
      const encrypted = secureStorage.encrypt(JSON.stringify(data));
      localStorage.setItem(key, encrypted);
      
      // 存儲驗證雜湊
      const verificationHash = CryptoJS.SHA256(encrypted + salt).toString();
      localStorage.setItem(`${key}_hash`, verificationHash);
    } catch (error) {
      console.error('Storage error:', error);
      return null;
    }
  },

  // 安全讀取資料
  getItem: (key) => {
    try {
      const encrypted = localStorage.getItem(key);
      if (!encrypted) return null;

      const decrypted = secureStorage.decrypt(encrypted);
      if (!decrypted) return null;

      const data = JSON.parse(decrypted);
      
      // 驗證資料完整性
      const storedHash = localStorage.getItem(`${key}_hash`);
      const calculatedHash = CryptoJS.SHA256(encrypted + data.salt).toString();
      
      if (storedHash !== calculatedHash) {
        console.error('Data integrity check failed');
        secureStorage.removeItem(key);
        return null;
      }

      // 檢查資料是否過期（例如 30 天）
      const now = new Date().getTime();
      if (now - data.timestamp > 30 * 24 * 60 * 60 * 1000) {
        secureStorage.removeItem(key);
        return null;
      }

      return data.value;
    } catch (error) {
      console.error('Retrieval error:', error);
      return null;
    }
  },

  // 安全刪除資料
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
      localStorage.removeItem(`${key}_hash`);
    } catch (error) {
      console.error('Removal error:', error);
    }
  },

  // 清除所有安全存儲的資料
  clear: () => {
    try {
      const keysToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.includes('_hash') || key === 'secure_key' || key === 'loginCredentials') {
          keysToRemove.push(key);
        }
      }
      keysToRemove.forEach(key => localStorage.removeItem(key));
    } catch (error) {
      console.error('Clear error:', error);
    }
  }
};

// 添加密碼顯示/隱藏的樣式組件
const PasswordInputWrapper = styled.div`
  position: relative;
  margin-bottom: 1.5em;
`;

const PasswordToggleIcon = styled.i`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #7f8c8d;
  transition: color 0.3s ease;
  z-index: 3;
  
  &:hover {
    color: #34495e;
  }
`;

const LoginForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    wechat_verification_code: ''
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [searchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const [userState, userDispatch] = useContext(UserContext);
  let navigate = useNavigate();
  const [status, setStatus] = useState({});
  const logo = getLogo();

  // 添加密碼顯示/隱藏的狀態
  const [showPassword, setShowPassword] = useState(false);

  // 在組件加載時檢查是否有保存的登入資訊
  useEffect(() => {
    const savedCredentials = secureStorage.getItem('loginCredentials');
    if (savedCredentials) {
      setInputs(prev => ({
        ...prev,
        username: savedCredentials.username || '',
        password: savedCredentials.password || ''
      }));
      setRememberMe(true);
    }

    if (searchParams.get('expired')) {
      showError('未登入或登入已過期，請重新登入！');
    }
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setStatus(status);
    }
  }, []);

  const [showWeChatLoginModal, setShowWeChatLoginModal] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  // 在組件卸載時清理敏感資料
  useEffect(() => {
    return () => {
      if (!rememberMe) {
        secureStorage.clear();
      }
    };
  }, [rememberMe]);

  async function handleSubmit(e) {
    setSubmitted(true);
    if (username && password) {
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        if (rememberMe) {
          // 加密存儲登入資訊
          secureStorage.setItem('loginCredentials', {
            username,
            password,
            timestamp: new Date().getTime()
          });
        } else {
          // 清除所有存儲的資訊
          secureStorage.clear();
        }

        userDispatch({ type: 'login', payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        
        if (username === 'root' && password === '123456') {
          navigate('/user/edit');
          showWarning('請立刻修改預設密碼！');
        } else {
          navigate('/token');
          showSuccess('登入成功！');
        }
      } else {
        showError(message);
      }
    }
  }

  return (
    <div style={{
      background: `
        linear-gradient(135deg, 
          rgba(255, 255, 255, 0.97) 0%,
          rgba(247, 249, 252, 0.97) 100%
        )
      `,
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2em',
      position: 'relative',
      overflow: 'hidden',
    }}>
      {/* 動態背景效果 */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `
          radial-gradient(circle at 20% 30%, rgba(74, 144, 226, 0.08) 0%, transparent 50%),
          radial-gradient(circle at 80% 70%, rgba(52, 152, 219, 0.08) 0%, transparent 50%),
          radial-gradient(circle at 50% 50%, rgba(41, 128, 185, 0.05) 0%, transparent 70%)
        `,
        animation: 'pulse 8s ease-in-out infinite alternate',
      }} />

      {/* 動態幾何圖形 */}
      <div style={{
        position: 'absolute',
        top: '10%',
        right: '15%',
        width: '200px',
        height: '200px',
        background: 'linear-gradient(135deg, rgba(74, 144, 226, 0.2) 0%, rgba(52, 152, 219, 0.2) 100%)',
        borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
        animation: 'morphing 15s ease-in-out infinite',
        opacity: 0.7,
      }} />

      <div style={{
        position: 'absolute',
        bottom: '15%',
        left: '10%',
        width: '150px',
        height: '150px',
        background: 'linear-gradient(135deg, rgba(52, 152, 219, 0.2) 0%, rgba(41, 128, 185, 0.2) 100%)',
        borderRadius: '60% 40% 30% 70% / 60% 30% 70% 40%',
        animation: 'morphing 12s ease-in-out infinite reverse',
        opacity: 0.5,
      }} />

      {/* 動畫樣式 */}
      <style>{`
        @keyframes morphing {
          0% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
          25% { border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%; }
          50% { border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%; }
          75% { border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%; }
          100% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
        }

        @keyframes pulse {
          0% { opacity: 0.3; }
          50% { opacity: 0.5; }
          100% { opacity: 0.3; }
        }
      `}</style>

      <div style={{
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(20px)',
        padding: '3em',
        borderRadius: '30px',
        boxShadow: '0 20px 40px rgba(0, 0, 0, 0.1), 0 0 100px rgba(74, 144, 226, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        maxWidth: '400px',
        width: '100%',
        textAlign: 'center',
        position: 'relative',
        zIndex: 2,
      }}>
        <Image 
          src={logo} 
          style={{
            width: '80px',
            marginBottom: '1.5em',
            animation: 'float 6s ease-in-out infinite',
          }} 
          centered 
        />
        <Header 
          as='h2' 
          style={{
            fontSize: '2em',
            fontWeight: '800',
            marginBottom: '1em',
            background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          用戶登入
        </Header>

        <Form size='large'>
          <Form.Input
            fluid
            icon='user'
            iconPosition='left'
            placeholder='使用者名稱 / 電子郵件地址'
            name='username'
            value={username}
            onChange={handleChange}
            style={{
              marginBottom: '1em',
            }}
          />
          
          <PasswordInputWrapper>
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='密碼'
              name='password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handleChange}
              style={{
                marginBottom: '0',  // 移除底部邊距，因為已經由 wrapper 控制
              }}
            />
            <PasswordToggleIcon
              className={`eye ${showPassword ? 'slash' : ''} icon`}
              onClick={() => setShowPassword(!showPassword)}
              title={showPassword ? '隱藏密碼' : '顯示密碼'}
            />
          </PasswordInputWrapper>
          
          {/* 添加"記住我"選項 */}
          <Form.Checkbox
            label='記住我'
            checked={rememberMe}
            onChange={(e, { checked }) => setRememberMe(checked)}
            style={{
              marginBottom: '1.5em',
              color: '#34495e',
              fontWeight: '500',
            }}
          />

          <Button
            fluid
            size='large'
            onClick={handleSubmit}
            style={{
              background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
              color: 'white',
              padding: '1em',
              fontSize: '1.1em',
              fontWeight: '600',
              borderRadius: '8px',
              border: 'none',
              boxShadow: '0 4px 15px rgba(52, 152, 219, 0.3)',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              cursor: 'pointer',
              letterSpacing: '0.5px',
              position: 'relative',
              overflow: 'hidden',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-2px) scale(1.02)';
              e.currentTarget.style.boxShadow = '0 8px 25px rgba(52, 152, 219, 0.4)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'translateY(0) scale(1)';
              e.currentTarget.style.boxShadow = '0 4px 15px rgba(52, 152, 219, 0.3)';
            }}
          >
            登入
          </Button>
        </Form>

        <Divider horizontal style={{ margin: '2em 0' }}></Divider>

        <div style={{ marginBottom: '1.5em' }}>
          <Link 
            to='/reset' 
            style={{
              color: '#3498db',
              textDecoration: 'none',
              fontWeight: '500',
              marginRight: '1em',
              transition: 'color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = '#2980b9';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = '#3498db';
            }}
          >
            忘記密碼
          </Link>
          |
          <Link 
            to='/register' 
            style={{
              color: '#3498db',
              textDecoration: 'none',
              fontWeight: '500',
              marginLeft: '1em',
              transition: 'color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = '#2980b9';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = '#3498db';
            }}
          >
            申請帳號
          </Link>
        </div>

        {status.github_oauth || status.wechat_login || status.lark_client_id ? (
          <div>
            <p style={{ 
              color: '#7f8c8d', 
              marginBottom: '1em', 
              fontWeight: '500' 
            }}>
              {/* 第三方登入 */}
            </p>
            <div style={{ 
              display: "flex", 
              justifyContent: "center", 
              gap: "20px" 
            }}>
              {status.wechat_login && (
                <Button
                  circular
                  icon='wechat'
                  style={{
                    background: '#09B83E',
                    color: 'white',
                    width: '40px',
                    height: '40px',
                    transition: 'all 0.3s ease',
                  }}
                  onClick={() => setShowWeChatLoginModal(true)}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'translateY(-2px)';
                    e.currentTarget.style.boxShadow = '0 5px 15px rgba(9, 184, 62, 0.3)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.boxShadow = 'none';
                  }}
                />
              )}
              {status.lark_client_id && (
                <Button
                  circular
                  style={{
                    background: "linear-gradient(135deg, #00D6B9, #2F73FF)",
                    padding: 0,
                    width: "40px",
                    height: "40px",
                    transition: 'all 0.3s ease',
                  }}
                  onClick={() => onLarkOAuthClicked(status.lark_client_id)}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'translateY(-2px)';
                    e.currentTarget.style.boxShadow = '0 5px 15px rgba(47, 115, 255, 0.3)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.boxShadow = 'none';
                  }}
                >
                  <Image
                    src={larkIcon}
                    style={{ width: "24px", height: "24px", margin: "auto" }}
                  />
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>

      {/* WeChat Login Modal */}
      <Modal
        onClose={() => setShowWeChatLoginModal(false)}
        open={showWeChatLoginModal}
        size='tiny'
        dimmer='blurring'
        style={{
          background: 'rgba(255, 255, 255, 0.95)',
          borderRadius: '15px',
          overflow: 'hidden',
        }}
      >
        <Modal.Content>
          <Modal.Description>
            <Header 
              as='h2'
              style={{
                textAlign: 'center',
                color: '#2c3e50',
                marginBottom: '1.5em',
              }}
            >
              微信登入
            </Header>
            <Image src={status.wechat_qrcode} size='medium' centered />
            <p style={{ 
              textAlign: 'center', 
              margin: '1.5em 0',
              color: '#34495e',
              lineHeight: '1.6',
            }}>
              掃描二維碼關注公眾號，並輸入「驗證碼」獲取登入碼（三分鐘內有效）
            </p>
            <Form size='large'>
              <Form.Input
                fluid
                icon='qrcode'
                iconPosition='left'
                placeholder='輸入驗證碼'
                name='wechat_verification_code'
                value={inputs.wechat_verification_code}
                onChange={handleChange}
                style={{ marginBottom: '1.5em' }}
              />
              <Button
                fluid
                size='large'
                style={{
                  background: '#09B83E',
                  color: 'white',
                  padding: '1em',
                  borderRadius: '8px',
                  transition: 'all 0.3s ease',
                }}
                onClick={async () => {
                  const res = await API.get(
                    `/api/oauth/wechat?code=${inputs.wechat_verification_code}`
                  );
                  const { success, message, data } = res.data;
                  if (success) {
                    userDispatch({ type: 'login', payload: data });
                    localStorage.setItem('user', JSON.stringify(data));
                    navigate('/');
                    setShowWeChatLoginModal(false);
                  } else {
                    showError(message);
                  }
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'translateY(-2px)';
                  e.currentTarget.style.boxShadow = '0 5px 15px rgba(9, 184, 62, 0.3)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'translateY(0)';
                  e.currentTarget.style.boxShadow = 'none';
                }}
              >
                確認登入
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  );
};

// 添加樣式組件
const StyledFormInput = styled(Form.Input)`
  input {
    border-radius: 50px !important;
    padding: 15px 25px !important;
    border: 2px solid rgba(52, 152, 219, 0.3) !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05) !important;
    font-size: 1.1em !important;
    transition: all 0.3s ease !important;

    &:focus {
      border-color: #3498db !important;
      box-shadow: 0 6px 20px rgba(52, 152, 219, 0.15) !important;
    }
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(135deg, #3498db 0%, #2980b9 100%) !important;
  color: white !important;
  padding: 1.2em !important;
  font-size: 1.1em !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  border: none !important;
  box-shadow: 0 10px 20px rgba(52, 152, 219, 0.3) !important;
  transition: all 0.3s ease !important;

  &:hover:not(:disabled) {
    transform: translateY(-3px) scale(1.05) !important;
    box-shadow: 0 15px 30px rgba(52, 152, 219, 0.4) !important;
  }

  &:active:not(:disabled) {
    transform: translateY(1px) !important;
  }

  &:disabled {
    opacity: 0.7 !important;
    cursor: not-allowed !important;
  }
`;

export default LoginForm;
