import React from 'react';
import { Container, Header, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div style={{
    background: `
      linear-gradient(135deg, 
        rgba(255, 255, 255, 0.97) 0%,
        rgba(247, 249, 252, 0.97) 100%
      )
    `,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
    position: 'relative',
    overflow: 'hidden',
  }}>
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `
        radial-gradient(circle at 20% 30%, rgba(74, 144, 226, 0.08) 0%, transparent 50%),
        radial-gradient(circle at 80% 70%, rgba(52, 152, 219, 0.08) 0%, transparent 50%),
        radial-gradient(circle at 50% 50%, rgba(41, 128, 185, 0.05) 0%, transparent 70%)
      `,
      animation: 'pulse 8s ease-in-out infinite alternate',
    }} />

    <div style={{
      position: 'absolute',
      top: '10%',
      right: '15%',
      width: '200px',
      height: '200px',
      background: 'linear-gradient(135deg, rgba(74, 144, 226, 0.2) 0%, rgba(52, 152, 219, 0.2) 100%)',
      borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
      animation: 'morphing 15s ease-in-out infinite',
      opacity: 0.7,
    }} />

    <div style={{
      position: 'absolute',
      bottom: '15%',
      left: '10%',
      width: '150px',
      height: '150px',
      background: 'linear-gradient(135deg, rgba(52, 152, 219, 0.2) 0%, rgba(41, 128, 185, 0.2) 100%)',
      borderRadius: '60% 40% 30% 70% / 60% 30% 70% 40%',
      animation: 'morphing 12s ease-in-out infinite reverse',
      opacity: 0.5,
    }} />

    <style>{`
      @keyframes morphing {
        0% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
        25% { border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%; }
        50% { border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%; }
        75% { border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%; }
        100% { border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%; }
      }

      @keyframes pulse {
        0% { opacity: 0.3; }
        50% { opacity: 0.5; }
        100% { opacity: 0.3; }
      }
    `}</style>

    <Container text style={{
      background: 'rgba(255, 255, 255, 0.8)',
      backdropFilter: 'blur(20px)',
      padding: '3em',
      borderRadius: '30px',
      boxShadow: '0 20px 40px rgba(0, 0, 0, 0.1), 0 0 100px rgba(74, 144, 226, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      textAlign: 'center',
      position: 'relative',
      zIndex: 2,
      transform: 'translateY(0)',
      transition: 'all 0.3s ease',
    }}>
      <Icon 
        name='warning circle' 
        size='huge' 
        style={{
          color: '#3498db',
          marginBottom: '0.5em',
          animation: 'float 6s ease-in-out infinite',
        }}
      />
      <Header 
        as='h1' 
        style={{
          fontSize: '3em',
          fontWeight: '800',
          marginBottom: '0.5em',
          background: 'linear-gradient(135deg, #2c3e50 0%, #3498db 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        404 - 頁面不存在
      </Header>
      <p style={{
        fontSize: '1.2em',
        color: '#34495e',
        marginBottom: '2em',
        lineHeight: '1.6',
      }}>
        很抱歉，您所尋找的頁面不存在。
        <br />
        請檢查網址是否正確，或返回首頁。
      </p>
      <Button 
        as={Link} 
        to='/'
        style={{
          background: 'linear-gradient(135deg, #3498db 0%, #2980b9 100%)',
          color: 'white',
          padding: '1em 2em',
          fontSize: '1.1em',
          fontWeight: '600',
          borderRadius: '8px',
          border: 'none',
          boxShadow: '0 4px 15px rgba(52, 152, 219, 0.3)',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          cursor: 'pointer',
          letterSpacing: '0.5px',
          position: 'relative',
          overflow: 'hidden',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = 'translateY(-2px) scale(1.02)';
          e.currentTarget.style.boxShadow = '0 8px 25px rgba(52, 152, 219, 0.4)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = 'translateY(0) scale(1)';
          e.currentTarget.style.boxShadow = '0 4px 15px rgba(52, 152, 219, 0.3)';
        }}
      >
        <div style={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Icon name='home' style={{ marginRight: '0.5em' }} />
          返回首頁
        </div>
        <div style={{
          position: 'absolute',
          top: '-50%',
          left: '-50%',
          right: '-50%',
          bottom: '-50%',
          background: 'radial-gradient(circle, rgba(255,255,255,0.3) 0%, transparent 70%)',
          transform: 'scale(1)',
          transition: 'transform 0.5s',
        }} />
      </Button>
    </Container>
  </div>
);

export default NotFound;
